import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Swal from "sweetalert2";

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfFormLoginComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: null })
  mailAutoLogin!: string;

  private password: string = null;
  private loginError: string = null;
  private working = false;
  private passwordFieldType: "password" | "text" = "password";
  private passwordObfuscated = true;
  private passwordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";

  get email(): string {
    return this.$store.getters.loginMail;
  }

  set email(email: string) {
    this.$store.dispatch("setLoginMail", email);
  }

  private userLogin() {
    const formData = new FormData();
    formData.append("username", this.email);
    formData.append("password", this.password);
    this.loginError = null;
    this.working = true;
    this.service
      .userLogin(formData)
      .then((response: any) => {
        this.$root.$emit("loadUser", () => {
          this.$emit("userLogged");
        });
        this.working = false;
      })
      .catch((error: any) => {
        const pendig = !!error?.response?.data?.includes('user.status.pending');
        this.working = false;
        if (pendig) {
          this.loginError = "df-form-login.form.error.pending";
          Swal.fire({
            title: this.$t("df-form-login.modal.account-pending.title").toString(),
            text: this.$t("df-form-login.modal.account-pending.body").toString(),
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-form-login.modal.account-pending.ok").toString(),
          });
        } else {
          this.loginError = "df-form-login.form.error.credentials";
        }
      });
  }

  private showForgottenPassword() {
    this.$emit("showForgottenPassword");
  }

  private changePasswordFieldType() {
    this.passwordObfuscated = !this.passwordObfuscated;
    this.passwordObfuscatedIcon = this.passwordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.passwordFieldType = this.passwordFieldType == "text" ? "password" : "text";
  }

}
